<template>
    <div class="customer">
      <v-container fluid>
         <v-layout justify-center align-center>
        <v-card class="cus-card">
          <v-card-text class="pt-4 pb-9 px-4">
            <div class="text-center">
              <v-flex class="text-center" shrink>
             <v-img class="mx-auto" src="@/assets/logo.png" alt="" width="auto" height="100%"
 max-width="650"></v-img>
  </v-flex>
            </div>
            <h3 class="text-center mt-6">ລົງທະບຽນສັ່ງຊື້ນ້ຳກັບ ບໍລິສັດນ້ຳດື່ມວາຣີລາວ</h3>
          <v-form  ref="form" lazy-validation>
            <!-- @submit.prevent="submit" -->
        <v-row>
          <v-col md="6" offset-md="3">
                  <v-text-field
                    label="ຊື່ *"
                    required
                    v-model="customer.name"
                    :rules="nameRules"
                  ></v-text-field>
                  <v-text-field
                    label="ນາມສະກຸນ *"
                    required
                    v-model="customer.surname"
                    :rules="nameSurname"
                  ></v-text-field>
                  <v-text-field
                    label="ເບີໂທ *"
                    required
                    v-model="customer.phone"
                    :rules="phoneRules"
                    type="number"
                    class="input-number"
                  ></v-text-field>
                  <p class="errors">
                    {{ server_errors.phone }}
                  </p>
                    <v-select
                    v-model="selectedDistrict"
                    :items="listDistricts"
                    item-text="name"
                    item-value="id"
                      required
                      label="ເລືອກເມືອງ *"
                      :rules="ruleDistrict"

                    >
                    </v-select>
                    <v-select
                    required
                    v-model="selectedVillage.id"
                    :items="listVillages"
                    item-text="name"
                    item-value="id"
                    label="ບ້ານ *"
                    :rules="ruleVillage"
                    >
                    </v-select>
                    <v-text-field
                      label="Email"
                      v-model="customer.email"
                    ></v-text-field>
                    <v-text-field
                      label="ຈຸດສັງເກດທີ່ຢູ່ (ຮ່ອມ,ຖະໜົນ) *"
                      v-model="customer.address"
                      :rules="addressRules"
                    ></v-text-field>

                  <v-btn
                    class="mr-4 vari mt-2"
                    @click="submit"
                    :loading="loading"
                    :disabled="loading"
                    large
                    block
                  >
                    ລົງທະບຽນ
                  </v-btn>
          </v-col>
        </v-row>
    </v-form>
      </v-card-text>
    </v-card>
  </v-layout>
      </v-container>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        loading:false,
        selectedDistrict: '',
        selectedVillage: '',
        listDistricts:[],
        listVillages:[],
        selectedDistrictAdd:'',
        filterVillages:[],
        phoneNumber: '',
        email: '',
        select: null,
        customer:{},
        packages:[],
        server_errors: {
          email: '',
          roleId:''
        },
        checkbox: null,
        rulePackage: [(v) => !!v || 'Package is required',],
        ruleDistrict: [(v) => !!v || 'District is required',],
        ruleVillage: [(v) => !!v || 'Village is required',],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        nameRules: [
          v => !!v || 'Name is required',
          v => (v && v.length >= 2) || 'Name must be less than 2 characters',
        ],
        nameSurname: [
          v => !!v || 'Surname is required',
          v => (v && v.length >= 2) || 'Name must be less than 2 characters',
        ],
        phoneRules: [
            (v) => !!v || "Phone is required",
            v => (v && v.length >= 4 && v.length <= 11) || 'Phone number must be  4 - 11 numbers',
          ],
          addressRules: [
            v => !!v || 'Address is required',
            v => (v && v.length >= 2) || 'Name must be less than 2 characters',
          ],
          toast: {
            value: true,
            color: 'success',
            msg: 'ທ່ານລົງທະບຽນສຳເລັດແລ້ວ'
          },
      }
    },

    methods: {

      submit() {
        if (this.$refs.form.validate() == true) {
          this.SubmitItem();
        }
      },
      SubmitItem() {
        this.loading = true;
        this.customer.village_id = this.selectedVillage.id;
        // this.customer.profile = "profile";
        (this.customer);
        this.$axios.post('register/customer', this.customer)
          .then(res => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                 this.clear();
                this.$store.commit("Toast_State", this.toast);
              }, 300);
            }
          }).catch(error => {
            this.loading = false;
           this.clear();
            if (error.response.status == 422) {
              var obj = error.response.data.errors;
              for (let [key, customer] of Object.entries(obj)) {
                this.server_errors[key] = customer[0];
              }
            }
          });
      },
      clear () {
        this.customer = {}
        this.selectedDistrict = ''
        this.$refs.form.reset();
      },
      getData(){
        this.$admin.get('list-packages').then(res => {
          setTimeout(() => {
              this.packages = res.data.listPackages;
              this.TableLoading =false;
          }, 100);
      }).catch(() => {
        this.TableLoading =false;
        this.$store.commit("Toast_State",this.toast_error);
      });
      this.$axios.get('list-ຈຸດສັງເກດes').then(res => {
        //  this.listVillages = res.data.listVillages;
        this.listDistricts = res.data.districts;
        this.filterVillages = res.data.listVillages;
          this.TableLoading =false;
        }).catch(() => {
          this.TableLoading =false;
          this.$store.commit("Toast_State",this.toast_error);
        });
      },
      FilterVillages(districtId){
        const result_checking = this.filterVillages.filter(item=>{
             return item.district_id == districtId;
        });
        this.listVillages = result_checking;
        this.selectedVillage = {...this.listVillages[0]};
    },
    },
    watch: {
        'selectedDistrict':function(dist_id){
          this.FilterVillages(dist_id);
        },
        'customer_edit.district_id':function(value){
          this.FilterVillages(value);
        },

        'customer.email': function () {
          this.server_errors.email = '';
        },
        'customer.phone': function () {
          this.server_errors.phone = '';
        },
        'customer_edit.email': function () {
          this.server_errors.email = '';
        },
        'customer_edit.phone': function () {
          this.server_errors.phone = '';
        },
    },
    created(){
      this.getData();
    }
  }
</script>
<style>
.vari{
  color:#fff !important;
  font-size: 18px !important;
  background-color:#00c1d2 !important;
}
.customer{
  /* background-color: #fff; */
  margin:0px;
}
.row{
  margin-top:0;
}
table.v-data-table thead th {
  font-size: 18px !important;
}
.v-data-table {
  font-size: 18px !important;
  font-weight: 300 !important;
}
.v-data-table tbody {
  font-size: 15px !important;
  font-weight: normal !important;
}
.cus-card{
  border: none !important;
  box-shadow:none !important;
}
.v-card__text{
  border:none;
}
.container--fluid{
  justify-content: center;
}
</style>
